var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h1", { staticStyle: { "margin-left": "4%" } }, [_vm._v("Profil")]),
      _c(
        "a-spin",
        {
          attrs: {
            spinning:
              !_vm.wilayas && !_vm.statuts && !_vm.secteurs && !_vm.adherant
          }
        },
        [
          _vm.adherant
            ? _c(
                "div",
                [
                  _c("DossierAdherant", {
                    attrs: {
                      adherant: _vm.adherant,
                      wilayas: _vm.wilayas,
                      "wilayas-professional": _vm.wilayasProfessional,
                      statuts: _vm.statuts,
                      secteurs: _vm.secteurs,
                      specialites: _vm.specialites,
                      communes: _vm.communes,
                      diplomes: _vm.diplomes,
                      exercices: _vm.exercices,
                      "list-dossier": _vm.listDossier,
                      "photo-list": _vm.photosList,
                      auto: _vm.getAuthorizationToken(),
                      current: _vm.current,
                      "profile-pic-upload-url": _vm.profilePicUploadUrl,
                      "accept-terms-and-conditions":
                        _vm.isTermsAndConditionsAccepted,
                      "is-admin": _vm.isAdmin,
                      "etat-activites": _vm.etatActivites,
                      "etat-actvite-modal-visible": _vm.etatActviteModalVisible,
                      processing: _vm.processing,
                      "saving-data": _vm.savingData,
                      "processing-etat-actvite": _vm.processingEtatActivite,
                      "saving-etat-actvite": _vm.savingEtatActvite,
                      "reglement-interieur": _vm.reglementInterieur,
                      "new-cotisation-form-component-key":
                        _vm.newCotisationFormComponentKey
                    },
                    on: {
                      save: function($event) {
                        return _vm.save($event)
                      },
                      addEtatActvite: function($event) {
                        return _vm.addEtatActvite($event)
                      },
                      updateEtatActvite: function($event) {
                        return _vm.updateEtatActvite($event)
                      },
                      deleteEtatActivite: function($event) {
                        return _vm.deleteEtatActivite($event)
                      },
                      deleteEtatActiviteFile: function($event) {
                        return _vm.deleteEtatActiviteFile($event)
                      },
                      getUser: _vm.getUser,
                      add_passif_reglement: function($event) {
                        return _vm.addPassifReglement($event)
                      },
                      update_profile_pic: _vm.getUser,
                      delete_dossier: function($event) {
                        return _vm.deleteDossier($event)
                      },
                      updated_soumission_state: function($event) {
                        return _vm.updateAdherentSoumissionState($event)
                      },
                      refresh_list_dossier: _vm.refreshListOfDossier,
                      previous: _vm.handlePreviousStepClick,
                      next: _vm.handleNextStepClick,
                      clear_profile_pic: _vm.handleClearProfilePic
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _c(
            "a-modal",
            {
              attrs: { title: _vm.successMessageTitle, centered: "" },
              on: {
                ok: function() {
                  return (_vm.modal2Visible = false)
                }
              },
              model: {
                value: _vm.modal2Visible,
                callback: function($$v) {
                  _vm.modal2Visible = $$v
                },
                expression: "modal2Visible"
              }
            },
            [
              _c("span", {
                domProps: { innerHTML: _vm._s(_vm.getSuccessMessage) }
              })
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }