<template>
  <div>
    <h1 style="margin-left:4%;">Profil</h1>
    <a-spin :spinning="!wilayas && !statuts && !secteurs && !adherant">
      <div v-if="adherant">
        <DossierAdherant
          :adherant="adherant"
          :wilayas="wilayas"
          :wilayas-professional="wilayasProfessional"
          :statuts="statuts"
          :secteurs="secteurs"
          :specialites="specialites"
          :communes="communes"
          :diplomes="diplomes"
          :exercices="exercices"
          :list-dossier="listDossier"
          :photo-list="photosList"
          :auto="getAuthorizationToken()"
          :current="current"
          :profile-pic-upload-url="profilePicUploadUrl"
          :accept-terms-and-conditions="isTermsAndConditionsAccepted"
          :is-admin="isAdmin"
          :etat-activites="etatActivites"
          :etat-actvite-modal-visible="etatActviteModalVisible"
          :processing="processing"
          :saving-data="savingData"
          :processing-etat-actvite="processingEtatActivite"
          :saving-etat-actvite="savingEtatActvite"
          :reglement-interieur="reglementInterieur"
          :new-cotisation-form-component-key="newCotisationFormComponentKey"
          @save="save($event)"
          @addEtatActvite="addEtatActvite($event)"
          @updateEtatActvite="updateEtatActvite($event)"
          @deleteEtatActivite="deleteEtatActivite($event)"
          @deleteEtatActiviteFile="deleteEtatActiviteFile($event)"
          @getUser="getUser"
          @add_passif_reglement="addPassifReglement($event)"
          @update_profile_pic="getUser"
          @delete_dossier="deleteDossier($event)"
          @updated_soumission_state="updateAdherentSoumissionState($event)"
          @refresh_list_dossier="refreshListOfDossier"
          @previous="handlePreviousStepClick"
          @next="handleNextStepClick"
          @clear_profile_pic="handleClearProfilePic"
        />
      </div>
      <a-modal
        v-model="modal2Visible"
        :title="successMessageTitle"
        centered
        @ok="() => (modal2Visible = false)"
      >
        <span v-html="getSuccessMessage"></span>
      </a-modal>
    </a-spin>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import moment from "moment";
import DossierAdherant from "@/components/common/dossier_adherant/Index";
import constants from "@/const/const";
// import TypeDemandeEditorVue from "../../admin/type_demandes/TypeDemandeEditor.vue";

const PHOTO_UPLOAD = `/user/upload-profile-picture`;

export default {
  name: "AdherentProfileIndex",
  created() {
    this.fetchData();
  },
  components: {
    DossierAdherant,
  },
  mounted() {
    if (this.editMode && this.adherant) {
      this.adherant.dtn = moment(this.adherant.dtn);
    }
  },
  data() {
    return {
      confirmDirty: false,
      autoCompleteResult: [],
      sexe: 0,
      current: 0,
      id: "",
      isAdmin: false,
      newEntriesTimer: 10000,
      processing: false,
      modal2Visible: false,
      processingEtatActivite: false,
      savingEtatActvite: false,
      savingData: false,
      successMessageTitle: "",
      etatActviteModalVisible: false,
      newCotisationFormComponentKey: 1,
    };
  },
  computed: {
    isTermsAndConditionsAccepted() {
      return (
        this.adherant &&
        this.adherant.etat !== this.ETAT_ADHERANT_PAS_ENCORE_SOUMIS
      );
    },
    profilePicUploadUrl() {
      return `${this.base_url}${PHOTO_UPLOAD}`;
    },
    ...mapGetters({
      listDossier: "getListDossierRequis",
      photosList: "getAdherentListOfProfilePhoto",
      getSuccessMessage: "getDemandeInscriptinSuccessMessage",
      etatActivites: "getUserEtatActivites",
      reglementInterieur: "getReglementInterieur"
    }),
    ...mapState({
      adherant: (state) => state.adherent.adherent,
      secteurs: (state) => state.secteurs.secteurs,
      statuts: (state) => state.statuts.statuts,
      specialites: (state) => state.specialites.specialites,
      communes: (state) => state.communes.communes,
      exercices: (state) => state.exercices.exercices,
      diplomes: (state) => state.diplomes.diplomes,
      wilayas: (state) => state.wilayas.wilayas,
      wilayasProfessional: (state) => state.wilayas.organisation_wilayas,
      base_url: (state) => state.auth.base_url,
    })
  },
  methods: {
    startProcessing() {
      this.processing = true;
    },
    stopProcessing() {
      this.processing = false;
    },
    startEtatActiviteProcessingSaving() {
      this.processingEtatActivite = true;
      this.savingEtatActvite = true;
    },
    stopEtatActiviteProcessingSaving() {
      this.processingEtatActivite = false;
      this.savingEtatActvite = false;
    },
    fetchData() {
      this.startProcessing();
      this.$store.cache
        .dispatch("fetchAdherent")
        .then(() => {
          this.getAdditioanlData();
          this.stopProcessing();
        })
        .catch(() => this.stopProcessing());
    },
    checkForNewEntries() {
      if (!Window.userNewMessagesEntriesTimeout) {
        Window.userNewMessagesEntriesTimeout = setInterval(() => {
          this.getUnreadMessagesCount();
        }, this.newEntriesTimer);
      }
    },
    getAdditioanlData() {
      this.$store.cache.dispatch("fetchWilayas");
      this.$store.cache.dispatch("fetchExercices");
      this.$store.cache.dispatch("fetchSpecialites");
      this.$store.cache.dispatch("fetchOrganisationWilayas");
      this.$store.cache.dispatch("fetchCommunes");
      this.$store.cache.dispatch("fetchDiplomes");
      this.$store.cache.dispatch("fetchSecteurs");
      this.$store.cache.dispatch("fetchMessageCommunication");
    },
    deleteDossier(id) {
      this.deleteDossierRequis({ doc_id: id })
        .then(() => {
          this.$_showSuccessMessage("Votre document est supprimer");
          this.refreshListOfDossier();
        })
        .catch(() => {
          this.$_throwAnError("Une erreur est survenue ");
        });
    },
    getUser() {
      this.startSaving();
      this.getAdherent().then(() => this.stopSaving());
    },
    refreshListOfDossier() {
      this.refreshListDossier().then(
        () => (this.newCotisationFormComponentKey = this.getRandomInt())
      );
    },
    addPassifReglement(passifReglement) {
      this.startSaving();
      this.storePassifReglement(passifReglement)
        .then(() => this.getUser())
        .catch(() => this.stopSaving());
    },
    save(adherant) {
      this.startSaving();
      if (this.adherant === null) {
        this.storeAdherent(adherant)
          .then(() => {
            this.current++;
            this.getUser();
          })
          .catch(() => this.stopSaving());
        return;
      }
      for (var key in this.adherant) {
        if (
          this.adherant.hasOwnProperty(key) &&
          moment.isMoment(this.adherant[key])
        ) {
          this.adherant[key] = this.formatDate(
            this.adherant[key],
            "YYYY-MM-DD"
          );
        }
      }
      this.updateAdherent({ ...this.adherant, ...adherant })
        .then(() => {
          this.current++;
          this.getUser();
        })
        .catch(() => this.stopSaving());
    },
    startSaving() {
      this.savingData = true;
    },
    stopSaving() {
      this.savingData = false;
    },
    displayDossierMessage(title) {
      this.modal2Visible = true;
      this.successMessageTitle = title;
    },
    updateAdherentSoumissionState() {
      let that = this;
      this.startProcessing();
      this.updateSoumissionState()
        .then(() => {
          that.$store.cache.dispatch("fetchAdherent");
          let title =
            that.adherant.etat !== this.ETAT_ADHERANT_PAS_ENCORE_SOUMIS
              ? constants.DOSSIER_SAVE_SUCCESS
              : constants.DOSSIER_SOUMIS_SUCCESS;
          this.fetchData();
          that.displayDossierMessage(title);
          that.stopProcessing();
        })
        .catch((er) => {
          this.stopProcessing();
          let messages = er.response.data.slice(0, 3);
          messages.forEach((message) => {
            this.$_throwAnError(message.message, 5);
          });
        });
    },
    handleClearProfilePic() {
      this.clearProfilePic(this.adherant.id).then(() => this.getAdherent());
    },
    addEtatActvite(etatActivite) {
      this.startProcessing();
      this.startEtatActiviteProcessingSaving();
      this.storeEtatActvite(etatActivite)
        .then(() => {
          this.etatActviteModalVisible = false;
          this.getUser();
        })
        .finally(() => {
          this.stopProcessing();
          this.stopEtatActiviteProcessingSaving();
        });
    },
    updateEtatActvite(etatActivite) {
      this.startProcessing();
      this.startEtatActiviteProcessingSaving();
      this.updateUserEtatActvite(etatActivite)
        .then(() => {
          this.etatActviteModalVisible = false;
          this.getUser();
        })
        .finally(() => {
          this.stopProcessing();
          this.stopEtatActiviteProcessingSaving();
        });
    },
    deleteEtatActivite(id) {
      this.startProcessing();
      this.startEtatActiviteProcessingSaving();

      this.deleteUserEtatActvite(id)
        .then(() => this.getUser())
        .finally(() => {
          this.stopProcessing();
          this.stopEtatActiviteProcessingSaving();
        });
    },
    deleteEtatActiviteFile(id) {
      this.startProcessing();
      this.deleteUserEtatActiviteFile(id)
        .then(() => this.getUser())
        .finally(() => this.stopProcessing());
    },
    ...mapActions({
      getAdherent: "fetchAdherent",
      getSpecialites: "fetchSpecialites",
      getCommunes: "fetchCommunes",
      getDiplomes: "fetchDiplomes",
      getReceivedMessages: "fetchReceivedMessages",
      getUnreadMessagesCount: "fetchUnreadMessagesCount",
      getModesReglement: "fetchModesReglement",
      updateSoumissionState: "validateAndUpdateAdherentSoumissionState",
      updateAdherent: "updatingAdherent",
      storeAdherent: "storeAdherent",
      storeEtatActvite: "storeUserEtatActivites",
      deleteUserEtatActvite: "destroyUserEtatActivite",
      deleteUserEtatActiviteFile: "destroyUserEtatActiviteFile",
      updateUserEtatActvite: "updateUserEtatActvite",
      storePassifReglement: "storePassifReglement",
      getWilayas: "fetchWilayas",
      refreshListDossier: "fetchAdherentListDossier",
      deleteDossierRequis: "destroyDossierRequiByAdherant",
      callPdfGenerator: "callPdfGenerator",
      clearProfilePic: "clearProfilePic",
    }),
    ...mapMutations(["clearSelectedAdherent"]),
    handlePreviousStepClick() {
      this.current--;
    },
    handleNextStepClick() {
      this.current++;
    },
  },
};
</script>
<style scoped>
.section_text {
  font-size: large;
  font-weight: bolder;
}

.adherent_form {
  height: auto;
}
</style>
